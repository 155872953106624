.menuContainer{
    width: 100%;
    padding: 5rem 0;
    background-color: #A8B69F;
    background-image: url(../../assets/fondoMenu.png);
    background-attachment: fixed;
}

.titleMenu{
    color: #6E8368;
    font-size: 5rem;
    padding: 1rem 0;
    margin: 0;
}

.buttonsMenu{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
}

.buttonsMenu button{
    width: 6rem;
    height: 6rem;
    border-radius: 5rem;
    margin: 0 2rem;
    border: solid 3px #6E8368;
    cursor: pointer;
    transition: all 0.4s;
}

.buttonsMenu button:hover{
    filter: opacity(70%);
    box-shadow: 0px 0px 10px 4px #6E8368;
}

.buttonsMenu p{
    color: #6E8368;
    font-weight: bold;
    font-size: 1.1rem;
}

.buttonPoke{
    background-image: url(../../assets/icon-poke.png);
    background-position: center;
    background-size: 4.5rem;
    background-repeat: no-repeat;
    background-color: #ffffff;
}

.buttonDrink{
    background-image: url(../../assets/icon-drink.png);
    background-position: center;
    background-size: 4.5rem;
    background-repeat: no-repeat;
    background-color: #ffffff;
}

.buttonWine{
    background-image: url(../../assets/icon-wine.png);
    background-position: center;
    background-size: 4.5rem;
    background-repeat: no-repeat;
    background-color: #ffffff;
}

.buttonIngredients{
    background-image: url(../../assets/carrot.png);
    background-position: center;
    background-size: 3rem;
    background-repeat: no-repeat;
    background-color: #ffffff;
}

@media (max-width: 640px){
    .buttonsMenu button{
        margin: 0 1rem;
    }
}

@media (max-width: 576px){
    .titleMenu{
        font-size: 4.5rem;
    }

    .buttonsMenu button{
        width: 5rem;
        height: 5rem;
    }

    .buttonWine{
        background-size: 3.5rem;
    }

    .buttonDrink{
        background-size: 3.5rem;
    }

    .buttonPoke{
        background-size: 3.5rem;
    }

    .buttonIngredients{
        background-size: 2.3rem;
    }
}

@media (max-width: 472px){
    .titleMenu{
        font-size: 3.5rem;
    }

    .buttonsMenu{
        flex-wrap: wrap;
    }

    .buttonsMenu button{
        margin: 0 2.5rem;
    }
}

@media (max-width: 360px){
    .buttonsMenu button{
        width: 4rem;
        height: 4rem;
        margin: 0 1.5rem;
    }

    .buttonWine{
        background-size: 2.5rem;
    }

    .buttonDrink{
        background-size: 3rem;
    }

    .buttonPoke{
        background-size: 3rem;
    }

    .buttonIngredients{
        background-size: 1.8rem;
    }
}