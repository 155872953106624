.navbar{
    display: flex;
    width: 100%;
    background-color: transparent;
    height: 6rem;
    align-items: center;
    justify-content: space-between;
}

.navbar-buttons{
    display: flex;
    width: 50%;
    justify-content: flex-end;
}

.burger{
    background-image: url('../../assets/bar.png');
    background-size: cover;
    width: 2rem;
    height: 2rem;
    margin: 0 2rem;
    display: none;
    cursor: pointer;
}

.dropdown{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background: #a8b69fba;
    backdrop-filter: blur(5px);
    overflow: hidden;
    height: 13rem;
    padding: 3rem 5rem;
    left: 50%;
    top: 7rem;
    width: 20rem;
    margin-left: -15rem;
}

.dropdown button{
    margin: 0.3rem 0;
}

.dropdown-social{
    margin-top: 1.2rem;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;500;700&display=swap');

.button{
    height: 2rem;
    color: #6E8368;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    background: none;
    position: relative;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color;
    padding: 0 1.5rem;
    font-family: 'Roboto Slab', serif;
}

.button:hover {
    color: #A8B69F;
}


.button:hover:after {
    width: 30%;
    left: 35%;
}

.button:after {
    content: "";
    pointer-events: none;
    bottom: -2px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 1px;
    background-color: #A8B69F;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
}

@media (max-width: 992px){
    .button:hover{
        color: #f8f8f7;
    }
}

@media (max-width: 992px) {
    .navbar-buttons, .social-icons{
        display: none;
    }

    .navbar{
        justify-content: space-between;
    }

    .burger{
        display: block;
    }
}

@media (max-width: 567px){
    .dropdown{
        width: 11rem;
        margin-left: -10.5rem;
    }
}

@media (max-width: 360px){
    .dropdown{
        width: 10rem;
        margin-left: -10rem;
    }
}