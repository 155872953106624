.scrollToTop{
    position: fixed;
    background-image: url(../../assets/arrow-up.png);
    background-size: cover;
    bottom: 40px;
    right: 60px;
    width: 3.8rem;
    height: 3.8rem;
    cursor: pointer;
    z-index: 100;
}