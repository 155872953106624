.subtitleMenu{
    font-size: 3rem;
    color: #6E8368;
}

.winesFlex{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.wineIcon{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

}

.wineIcon img{
    width: 4.5rem;
    padding-left: 2.7rem;
    padding-right: 0.2rem;
}

.winesContainer{
    background-color: #FBFAFA;
    margin: 1rem 5rem 4rem 5rem;
    padding: 3rem 7rem;
    border: solid 5px #6E8368;
    color: #6E8368;
    width: auto;
    max-width: 50rem;
    min-width: 45rem;
}

@media (max-width: 992px){
    .winesContainer{
        padding: 3rem 5rem;
        min-width: 0;
        width: 70%;
    }
}


@media (max-width: 768px){
    .winesContainer{
        padding: 3rem;
        margin: 1rem 0 3rem 0;
    }

}
@media (max-width: 682px){
    .wineIcon img{
        width: 3.5rem;
        padding-left: 1.7rem;
        padding-right: 0.5rem;
    }
}

@media (max-width: 576px){
    .subtitleMenu576px{
        font-size: 2.3rem;
        padding: 0 1.5rem;
    }
}

@media (max-width: 410px){
    .winesContainer{
        padding: 3rem 2rem;
        margin: 1rem 0 3rem 0;
        width: 70%;
    }
}

@media (max-width: 386px){
    .wineIcon img{
        padding: 0 0.7rem;
    }
}

@media (max-width: 370px){
    .wineIcon img{
        padding: 0 0.5rem;
    }
}

@media (max-width: 350px){
    .wineIcon img{
        padding: 0 0.4rem;
    }
}

@media (max-width: 339px){
    .wineIcon img{
        padding: 0 0.25rem;
    }
}

@media (max-width: 328px){
    .wineIcon img{
        padding: 0 0.1rem;
    }
}