.whoWeAre{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5rem;
    background-image: url(../../assets/fondoChiSiamo.png);
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.whoWeAreTitle{
    /* color: #6e8368; */
    color: #6e8368;
    font-size: 5rem;
    margin: 5rem 0 2rem 0;
}

.whoWeAreSubtitle{
    font-size: 1.5rem;
    color: #6e8368;
    padding: 1rem 0;

}

.whoWeAreBox{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 14rem;
}

.whoWeAreBox img{
    width: 25rem;
    margin: 0 2rem;
    box-shadow:
    0px 0.7px 1.4px -16px rgba(0, 0, 0, 0.348),
    0px 2px 3.8px -16px rgba(0, 0, 0, 0.5),
    0px 4.8px 9px -16px rgba(0, 0, 0, 0.652),
    0px 16px 30px -16px rgba(0, 0, 0, 1)
    ;
    border-radius: 1.3rem;
    transition: 0.7s;
}

.whoWeAreBox img:hover{
    transform: scale(1.01);
    box-shadow:
    0px 2.2px 3.6px -59px rgba(0, 0, 0, 0.348),
    0px 6px 10px -59px rgba(0, 0, 0, 0.5),
    0px 14.5px 24.1px -59px rgba(0, 0, 0, 0.652),
    0px 48px 80px -59px rgba(0, 0, 0, 1)
    ;
}

.whoWeAreText{
    color: #A8B69F;
    text-align: left;
    font-size: 1.4rem;
    padding: 0 2rem;
    line-height: 2rem;
}

@media (max-width: 1200px){
    .whoWeAreBox{
        flex-direction: column;
        padding: 2rem 10rem;
    }

    .reverse{
        flex-direction: column-reverse;
    }
    
    .whoWeAreText{
        text-align: justify;
    }

    .whoWeAreBox img{
        margin: 3rem 2rem 0 2rem;
    }

    .whoWeAreSubtitle{
        padding: 0rem 10rem;
        margin-top: 3rem;
    }
}

@media (max-width: 768px){
    .whoWeAreBox{
        padding: 2rem 0rem;
    }

    .whoWeAreSubtitle{
        padding: 0 6rem;
    }

    .whoWeAreText{
        padding: 0 3rem;
    }
}

@media (max-width: 576px){
    .whoWeAre{
        background-image: url(../../assets/fondo576px.png);
    }

    .whoWeAreTitle{
        font-size: 4rem;
        padding: 0 3rem;
    }

    .whoWeAreBox{
        padding: 2rem 0;
    }

    .whoWeAreText{
        font-size: 1.2rem;
        padding: 0 5rem;
    }

    .whoWeAreBox img{
        width: 20rem;
    }

    .whoWeAreSubtitle{
        padding: 0 3rem;
    }
}

@media (max-width: 420px){
    .whoWeAreTitle{
        font-size: 3.1rem;
    }

    .whoWeAreText{
        padding: 0 3rem;
    }

    .whoWeAreBox img{
        margin: 3rem 0 0 0;
        width: 18rem;
    }
}