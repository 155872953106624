.bevande{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: .6rem 0;
}

.bevande p{
    text-align: left;
}

@media (max-width: 768px){
    .bevande p{
        max-width: 18rem;
        font-size: .9rem;
    }
}

@media (max-width: 576px){
    .bevande p{
        max-width: 10rem;
    }
}

@media (max-width: 410px){
    .bevande p{
        font-size: .8rem;
        max-width: 8rem;
    }
}