@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;500;700&display=swap');
body{
  font-family: 'Roboto Slab', serif;
  overflow-x: hidden;
}

.App {
  text-align: center;
  background-color: #f8f8f7;
}


