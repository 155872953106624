.wine{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .6rem 0;
    text-align: left;
}

.wineName{
    font-size: 1.2rem;
}

.wine div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 10rem;
    padding: 0 1rem;
}

@media (max-width: 768px){
    .wineName{
        max-width: 20rem;
    }

    .wineName{
        font-size: .9rem;
        max-width: 12rem;
    }

    .winePrice{
        font-size: .9rem;
    }
}

@media(max-width: 682px){
   .wine div{
        width: 8rem;
   }
}

@media(max-width: 576px){
    .wineName{
        max-width: 7rem;
    }
}

@media (max-width: 410px){
    .wineName{
        font-size: .8rem;
    }

    .winePrice{
        font-size: .8rem;
    }
}