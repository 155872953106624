figure.snip1033 {
    color: #fff;
    position: relative;
    overflow: hidden;
    width: 31rem;
    background-color: #6E8368;
/*     box-shadow: #6E8368 5px 5px, #7e9677 10px 10px, #97b48f 15px 15px, #8eaa87 20px 20px, #acd0a2 25px 25px;*/ 
    box-shadow:
    0px 1.2px 2.5px -30px rgba(0, 0, 0, 0.348),
    0px 3.3px 6.9px -30px rgba(0, 0, 0, 0.5),
    0px 7.8px 16.6px -30px rgba(0, 0, 0, 0.652),
    0px 26px 55px -30px rgba(0, 0, 0, 1)
    ;
    border-radius: 1.5rem;
}


figure.snip1033 img {
    opacity: 1;
    width: 100%;
    transform: scale(1.05);
    position: relative;
    transition: 1.5s;
}

figure.snip1033 img:hover {
    transform: scale(1.1);
}

@media (max-width: 472px) {
    figure.snip1033{
        margin: 0;
    }
}

