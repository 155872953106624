@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');

.presentation{
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/fondoMonstera.png);
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
    border-bottom: solid 15px #6e8368;
}

.presentation div{
    width: 40%;
}

.title{
    font-size: 5rem;
    text-align: left;
    color: #A8B69F;
    padding: 4rem 0rem;
    margin: 0;
    letter-spacing: -1px;
}

.title span{
    color: #6E8368;
}

.hawai{
    text-decoration: line-through;
}

.italy{
    font-family: 'Shadows Into Light', cursive;
    text-transform: uppercase;
    font-size: 1em;
}

.containerImg{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.information{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7rem 0;
    background-image: url(../../assets/fondo2.png);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    border-bottom: solid 15px #A8B69F;
}

.pictures{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 3rem;
}

.pictures img{
    margin: 1.5rem 1.5rem;
    object-fit: cover;
    box-shadow:
    0px 0.7px 1.4px -16px rgba(0, 0, 0, 0.348),
    0px 2px 3.8px -16px rgba(0, 0, 0, 0.5),
    0px 4.8px 9px -16px rgba(0, 0, 0, 0.652),
    0px 16px 30px -16px rgba(0, 0, 0, 1)
    ;
    width: 20rem;
    border-radius: 1.3rem;
    position: relative;
    transition: 0.7s;
}

.pictures img:hover{
    transform: scale(1.01);
    box-shadow:
    0px 2.2px 3.6px -59px rgba(0, 0, 0, 0.348),
    0px 6px 10px -59px rgba(0, 0, 0, 0.5),
    0px 14.5px 24.1px -59px rgba(0, 0, 0, 0.652),
    0px 48px 80px -59px rgba(0, 0, 0, 1)
    ;
}

.textInfo{
    width: 40%;
    text-align: right;
    padding: 0 5rem;
    color: #6E8368;
}

.textInfo h2{
    font-size: 4.7rem;
    padding: 1rem 0;
    letter-spacing: -1px;
}

@media (max-width: 1483px){
    .pictures img{
        margin: 1rem;
        width: 16rem;
    }
}

@media (max-width: 1293px){
    .title{
        font-size: 4.5rem;
    }
    .pictures img {
        margin: 0.8rem;
        width: 15rem;
    }

    .textInfo h2{
        font-size: 4rem;
    }
}

@media (max-width: 1178px){
    .presentation{
        flex-direction: column;
        align-items: flex-start;
        padding: 0 5rem;
        background-image: url(../../assets/fondo992px.png);
        padding-bottom: 7rem;
    }

    .pictures img{
        width: 18rem;
        margin: 2rem 5rem;
    }

    .presentation div{
        width: 100%;
    }

    .information{
        flex-direction: column-reverse;
        padding: 3rem 0;
        background-image: url(../../assets/fondo992px2.png);
    }

    .pictures{
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .textInfo{
        width: 100%;
        padding: 0;
    }

    .textInfo h2{
        padding: 0 5rem 0 0;
    }
}

@media (max-width: 896px){
    .pictures img{
        margin: 2rem;
    }
}

@media (max-width: 576px){
    .presentation{
        padding: 0 2rem 4rem 2rem;
    }

    .title{
        font-size: 4.05rem;
        padding: 3rem 0;
    }

    .textInfo h2{
        font-size: 3.75rem;
        padding: 0 3rem 0 0rem;
    }

    .none{
        display: none;
    }
}

@media (max-width: 472px){
    .title{
        font-size: 3.5rem;
    }

    .textInfo h2{
        font-size: 3.25rem;
    }
}

@media (max-width: 416px){
    .title{
        font-size: 3rem;
    }

    .textInfo h2{
        font-size: 2.75rem;
    }
}
