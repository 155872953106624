.social{
    display: flex;
    align-items: center;
    justify-content: center;
}

.social figure{
    margin: 0;
    padding-bottom: 0.2rem;
    position: relative;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color;
}

.social figure:hover:after {
    width: 30%;
    left: 35%;
}

.social figure:after {
    content: "";
    pointer-events: none;
    bottom: -2px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: #A8B69F;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
}

.social img{
    width: 1.6rem;
    padding: 0 0.5rem;
    cursor: pointer;
}


