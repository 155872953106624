.whereWeAre{
    background-image: url(../../assets/fondoDoveSiamo.png);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.whereWeAreFlex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FBFAFA;
    padding: 2rem 0 ;
}

.whereWeAreTitle{
    font-size: 5rem;
    margin: 5rem 0 2rem 0;
}

.whereWeAreSubtitle{
    font-size: 3.5rem;
    margin: 3rem 0 0 0;
}

.whereWeAreSubtitle2{
    font-size: 3rem;
}

.whereWeAreText{
    font-size: 1.8rem;
}

.whereWeAreLocation{
    display: flex;
    align-items: center;
    justify-content: center;
}

.whereWeAreLocation img{
    width: 3rem;
    margin-right: 1rem;
}

.whereWeAreLocation p{
    font-size: 1.2rem;
}

.whereWeAreFornitores{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 3rem;
}

.whereWeAreFornitores img{
    width: 13rem;
    margin: 1rem 4rem;
    border-radius: 1rem;
    box-shadow:
    0px 0.7px 1.4px -16px rgba(0, 0, 0, 0.348),
    0px 2px 3.8px -16px rgba(0, 0, 0, 0.5),
    0px 4.8px 9px -16px rgba(0, 0, 0, 0.652),
    0px 16px 30px -16px rgba(0, 0, 0, 1);
    transition: 0.7s;
    cursor: pointer;
}

.deliveroo{
    width: 9rem;
    border-radius: 12px;
    margin: 0;
    box-shadow: 0px 11px 20px -10px rgb(0 0 0 / 67%);

}

.whereWeAreImages{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

figure.contain{
    margin: 0;
    position: relative;
    overflow: hidden;
    width: 25rem;
    height: 35rem;
    box-shadow:
    0px 0.7px 1.4px -16px rgba(0, 0, 0, 0.348),
    0px 2px 3.8px -16px rgba(0, 0, 0, 0.5),
    0px 4.8px 9px -16px rgba(0, 0, 0, 0.652),
    0px 16px 30px -16px rgba(0, 0, 0, 1);
    border-radius: 1.2rem;
    margin: 3rem;
}

figure.contain img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
    transition: 1.1s;
}

figure.contain img:hover{
        transform: scale(1.05);
}

@media(max-width: 1488px){
    figure.contain{
        width: 20rem;
        height: 30rem;
        margin: 1rem;
    }
}

@media(max-width: 1343px){
    .whereWeAreFornitores{
        max-width: 60rem;
    }
}

@media(max-width: 1055px){
    figure.contain{
        width: 25rem;
        height: 35rem;
        margin: 4rem;
    }
}

@media(max-width: 576px){
    .whereWeAreFlex{
        padding: 0;
    }

    .whereWeAreTitle{
        font-size: 4rem;
    }

    .whereWeAreSubtitle{
        font-size: 3rem;
    }

    .whereWeAreSubtitle2{
        font-size: 2.6rem;
        padding: 3rem 2rem 0 2rem;
    }

    .whereWeAreText{
        font-size: 1.3rem;
        padding: 0 1rem;
    }

    .whereWeAreFornitores img{
        width: 12rem;
        margin: 1rem 1rem;
    }
}

@media (max-width: 472px){
    .whereWeAreTitle{
        font-size: 3.1rem;
    }

    .whereWeAreSubtitle{
        font-size: 2.7rem;
    }

    .whereWeAreText{
        font-size: 1.1rem;
        padding: 0 3rem;
        margin-bottom: 3rem;
    }

    figure.contain{
        height: auto;
        margin: 1.8rem;
    }

    figure.contain img{
        transform: scale(1.03);
    }

    .whereWeAreFornitores img{
        width: 9rem;
    }
}

