.menuPoke{
    width: 35rem;
    height: 20rem;
    background-color: #FBFAFA;
    color: #6E8368;
    border: solid 5px #6E8368;
    transition: all 0.5s;
    margin: 2rem;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
 
.menuPoke:hover {
    transform: scale(1.01);
    box-shadow: rgb(0 0 0 / 30%) 0px 10px 20px 0px, rgb(0 0 0 / 22%) 0px 10px 10px;
}
  
.menuPoke:active {
    transform: scale(0.95) rotateZ(1.7deg);
}

.pokeData{
    padding: 0 3rem;
    font-size: 1.1rem;
}

.size{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: .5rem;
    width: 70%;
    align-self: center;
    font-size: 13px;
}

.size p{
    margin: 0;
    font-size: 1rem;
}
.size p span{
    font-size: 1.2rem;

}

.regular{
    width: 5rem;
}

.small{
    width: 4.4rem;
}

@media (max-width: 567px){
    .pokeData{
        padding: 0 1.5rem;
    }

    .menuPoke{
        height: auto;
        padding: 2.5rem 1rem;
        margin: 2rem 1rem;
    }
}

@media (max-width: 472px){
    .pokeData{
        font-size: 1rem;
    }

    .menuPoke h1{
        font-size: 1.5rem;
    }

    .size p{
        font-size: .9rem;
    }

    .size p span{
        font-size: 1rem;
    }
}