.footer{
    background-color: #677f67;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 4rem;
}

.logoLocation, .orari, .delivery{
    min-width: 23rem;
}

.location{
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    margin-top: 2rem;
    margin-left: 1rem;
    color: #A8B69F;
    font-size: 1.1rem;
}

.location img{
    width: 3rem;
    margin-right: 2rem;
}

.logoFooter{
    width: 15rem;
}

.logoLocation{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.copyright{
    width: 100%;
    padding: 4rem 0 1rem 0;
    color: #FBFAFA;
}

.orari{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.clock{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
}

.orari img{
    width: 3rem;
    margin-right: 1rem;

}

.orari h2{
    font-size: 2.5rem;
    color: #A8B69F;
    margin: 0;
}

.orari th{
    padding: .4rem 1rem;
    color: #A8B69F;
}

tr th{
    text-align: left;
}

tr th:first-child{
    color: #FBFAFA;
}

.delivery{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #A8B69F;
}

.top{
    margin-top: 1rem;
}

.deliveroo2{
    width: 9rem;
    border-radius: 12px;
    margin: 0;
}

.deliveroo2 h2{
    font-size: 2.2rem;
    margin: 0;
    font-weight: normal;
}

.deliveroo2 img{
    width: 7rem;
    border-radius: 10px;
    margin: 2rem 0;
    cursor: pointer;
}

.follow h2{
    font-size: 2.05rem;
    margin: 0;
    margin: 1rem 0; 
    font-weight: normal;
}

.follow img{
    width: 1.7rem;
    cursor: pointer;
    margin-bottom: 1rem;
}

@media (max-width: 1103px){
    .footer{
        align-items: center;
        justify-content: flex-start;
        padding-top: 3rem;
    }

    .top{
        margin-top: 4rem;
    }

    .margin{
        margin-left: .8rem;
    }
}

@media (max-width: 472px){
    .logoLocation, .orari, .delivery {
        min-width: auto;
    }

    .location{
        font-size: 1rem;
    }

    .deliveroo h2{
        text-align: left;
        font-size: 1.7rem;
    }

    .follow h2{
        text-align: left;
        font-size: 1.7rem;
    }

    .orari{
        font-size: 0.8rem;
    }
}