.menuSuggestions{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 2rem 0;
}

.subtitleMenu{
    font-size: 3rem;
    color: #6E8368;
}

@media (max-width: 576px){
    .subtitleMenu576px{
        font-size: 2.5rem;
    }
}