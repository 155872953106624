.ingredientiContainer{
    background-color: #FBFAFA;
    padding: 2rem 2rem;
    margin: 1rem 0;
    color: #6E8368;
    width: 65%;
    border: solid 5px #6E8368;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.misuraContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}

.misura{
    padding: 2rem 3rem;
    margin: 0 2rem;
    width: 20rem;
}

.misura p{
    text-align: left;
    font-size: 1.1rem;
}

.price{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price h4{
    font-size: 1.7rem;
    margin: 0;
    margin-bottom: .5rem;
}

.price p{
    font-size: 1.2rem;
    margin: 0;
    margin-bottom: .5rem;
    font-weight: bold;
}

.scelteContainer{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.scelte{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 1rem 4rem;
    padding: 1rem;
    width: 10rem;
}

.cursive{
    font-style: italic;
}

.titleScelte{
    text-align: left;
    font-size: 1.4rem;
    padding-bottom: .5rem;
}

.textScelte{
    list-style: square inside;
    padding: 0;
    text-align: left;
    font-size: 1.1rem;
    margin: 2rem 0;
}

.textScelte li{
    margin: .4rem 0;
    width: 15rem;
}

.boxScelte{
    width: 10rem;
    text-align: left;
}

.underline{
    border-bottom: solid 2px #6E8368;
    
}

@media (max-width: 1370px){
    .scelte{
        margin: 1rem 3rem;
    }
}

@media (max-width: 1235px){
    .scelte{
        margin: 1rem 2rem;
    }

    .misura{
        margin: 0;
    }
}

@media (max-width: 1030px){
    .misura{
        padding: 2rem 1rem;
    }

    .ingredientiContainer{
        padding: 2rem 7rem;
    }
}

@media (max-width: 840px){
    .price h4{
        font-size: 1.3rem;
    }

    .scelte{
        margin: 1rem .8rem;
    }

    .misura{
        width: 18rem;
    }

    .misura p{
        font-size: 1rem;
    }

    .titleScelte{
        font-size: 1.25rem;
    }

    .textScelte{
        font-size: 1rem;
    }

    .textScelte li{
        width: 12rem;
    }
}

@media (max-width: 700px){
    .misuraContainer{
        flex-direction: column;
    }

    .misura{
        width: 20rem;
    }

    .scelteContainer{
        flex-direction: column;
    }

    .scelte{
        margin: 0;
    }

    .boxScelte{
        width: 20rem;
    }

    .ingredientiContainer{
        padding: 2rem 4rem;
    }

    .textScelte li{
        width: 15rem;
    }
}

@media(max-width: 430px){
    .ingredientiContainer{
        padding: 2rem 3rem;
    }
}

@media(max-width: 380px){
    .misura{
        width: 15rem;
    }

    .boxScelte{
        width: 15rem;
    }

    .ingredientiContainer{
        padding: 2rem 2.5rem;
    }
}
