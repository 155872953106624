.subtitleMenu{
    font-size: 3rem;
    color: #6E8368;
}

.drinkSuggestions{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 2rem 0;
    flex-wrap: wrap;
}

.suggestionsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 4rem;
    margin: 1rem 0;
    background-color: #FBFAFA;
    width: 33%;
    border: solid 5px #6E8368;
    color: #6E8368;
    min-width: 30rem;
}

.comment{
    color: #6E8368;
    font-size: 1.1rem;
}

@media (max-width: 768px){
    .suggestionsContainer{
        min-width: 0;
        width: 75%;
        padding: 2rem;
    }
}

@media (max-width: 576px){
    .subtitleMenu576px{
        font-size: 2.5rem;
    }
}